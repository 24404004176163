import React, { useRef, useState } from 'react';
import TogglePlan from './TogglePlan';
import { useTranslation } from 'react-i18next';
// import {
//   HighVolumePlansM,
//   HighVolumePlansW,
//   HighVolumePlansY,
//   pricingPlansM,
//   pricingPlansW,
//   pricingPlansY,
// } from './jsonData';
import PlanCard from './PlanCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { LeftIcon, RightIcon } from '../../assets/Icons/Pricing/pricingIcons';
import { useWindowSize } from '../../Hooks/useWindowSize';
import SkeletonCard from './SkeletonCard';

const Plans = ({ allPlansData, isLoading }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const { t } = useTranslation();
  const pricingOptions = [
    // { id: 1, name: t('WeeklyBilling'), val: 'week' },
    { id: 2, name: t('MonthlyBilling'), val: 'month' },
    { id: 3, name: t('YearlyBilling'), val: 'year' },
  ];
  const sliderRef = useRef();
  const sliderSmallRef = useRef();
  const [selected, setSelected] = useState(pricingOptions[1]);
  // const [lowVolumPlan, setLowVolumPlan] = useState(pricingPlansM);
  // const [highVolumPlan, setHightVolumPlan] = useState(HighVolumePlansM);
  // useEffect(() => {
  //   if (selected.val === 'weekly') {
  //     setLowVolumPlan(pricingPlansW);
  //     setHightVolumPlan(HighVolumePlansW);
  //   } else if (selected.val === 'monthly') {
  //     setLowVolumPlan(pricingPlansM);
  //     setHightVolumPlan(HighVolumePlansM);
  //   } else {
  //     setLowVolumPlan(pricingPlansY);
  //     setHightVolumPlan(HighVolumePlansY);
  //   }
  // }, [selected]);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const updateButtonState = () => {
    if (sliderRef.current) {
      setIsBeginning(sliderRef.current.swiper.isBeginning);
      setIsEnd(sliderRef.current.swiper.isEnd);
    }
  };
  const updateButtonState1 = () => {
    if (sliderSmallRef.current) {
      setIsBeginning(sliderSmallRef.current.swiper.isBeginning);
      setIsEnd(sliderSmallRef.current.swiper.isEnd);
    }
  };
  const handlePrev = () => {
    sliderRef.current.swiper.slidePrev();
    updateButtonState();
  };
  const handlePrev1 = () => {
    sliderSmallRef.current.swiper.slidePrev();
    updateButtonState1();
  };
  const handleNext = () => {
    sliderRef.current.swiper.slideNext();
    updateButtonState();
  };
  const handleNext1 = () => {
    sliderSmallRef.current.swiper.slideNext();
    updateButtonState1();
  };
  return (
    <>
      <div className="mt-[100px] flex flex-col items-center m-auto max-w-[1440px] max_sm:px-5 max_md:px-10">
        <TogglePlan
          selected={selected}
          setSelected={setSelected}
          pricingOptions={pricingOptions}
          width
        />

        <div className="w-full flex items-center justify-center gap-5 max_md:hidden">
          {allPlansData?.length > 0 && (
            <>
              <div
                onClick={handlePrev}
                className="cursor-pointer max_1440:hidden"
              >
                <LeftIcon color={isBeginning ? '#CED8E8' : '#A9CFFF'} />
              </div>
              <div
                onClick={handlePrev1}
                className="hidden cursor-pointer max_1440:block"
              >
                <LeftIcon color={isBeginning ? '#CED8E8' : '#A9CFFF'} />
              </div>
            </>
          )}
          <div className="mt-10 w-full max-w-[1240px] max_1440:hidden">
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiperPlan"
              ref={sliderRef}
              // speed={1100}
            >
              <SwiperSlide>
                <div className="w-full flex gap-4 justify-center">
                  {isLoading ? (
                    <>
                      {Array(5)
                        .fill(null)
                        .map((_, i) => (
                          <React.Fragment key={i}>
                            <SkeletonCard />
                          </React.Fragment>
                        ))}
                    </>
                  ) : (
                    <>
                      {allPlansData?.slice(0, 5)?.map((planInfo, index) => {
                        return (
                          <React.Fragment key={index}>
                            <PlanCard planInfo={planInfo} selected={selected} />
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}

                  {/* {lowVolumPlan?.map((planInfo, index) => {
                    return (
                      <React.Fragment key={index}>
                        <PlanCard planInfo={planInfo} />
                      </React.Fragment>
                    );
                  })} */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full flex gap-6 justify-center">
                  {allPlansData?.slice(5, 9)?.map((planInfo, index) => {
                    return (
                      <React.Fragment key={index}>
                        <PlanCard
                          planInfo={planInfo}
                          fullWidth
                          selected={selected}
                        />
                      </React.Fragment>
                    );
                  })}
                  {/* {highVolumPlan?.map((planInfo, index) => {
                    return (
                      <React.Fragment key={index}>
                        <PlanCard planInfo={planInfo} fullWidth />
                      </React.Fragment>
                    );
                  })} */}
                </div>
              </SwiperSlide>
            </Swiper>
            {allPlansData.length > 0 && (
              <p className="subParagraphText text-ButtonPrimary mt-4">
                *Carrier surcharges for voice/SMS are not included.
              </p>
            )}
          </div>
          {/* SMALL DESKTOP */}
          <div className="hidden mt-10 w-full max-w-[822px] max_1440:block max_md:hidden">
            <Swiper
              slidesPerView={3}
              // spaceBetween={30}
              pagination={true}
              modules={[Pagination]}
              className="mySwiperPlan"
              ref={sliderSmallRef}
              // speed={1100}
            >
              {isLoading ? (
                <>
                  {Array(3)
                    .fill(null)
                    .map((_, i) => (
                      <SwiperSlide key={i}>
                        <SkeletonCard />
                      </SwiperSlide>
                    ))}
                </>
              ) : (
                <>
                  {allPlansData?.map((planInfo, index1) => {
                    return (
                      <SwiperSlide key={index1 + 1}>
                        <PlanCard planInfo={planInfo} selected={selected} />
                      </SwiperSlide>
                    );
                  })}
                </>
              )}
            </Swiper>
            {allPlansData.length > 0 && (
              <p className="subParagraphText text-ButtonPrimary mt-4">
                *Carrier surcharges for voice/SMS are not included.
              </p>
            )}
          </div>

          {allPlansData?.length > 0 && (
            <>
              <div
                onClick={handleNext}
                className="cursor-pointer max_1440:hidden"
              >
                <RightIcon color={isEnd ? '#CED8E8' : '#A9CFFF'} />
              </div>
              <div
                onClick={handleNext1}
                className="hidden cursor-pointer max_1440:block"
              >
                <RightIcon color={isEnd ? '#CED8E8' : '#A9CFFF'} />
              </div>
            </>
          )}
        </div>

        {/* SMALL DESKTOP AND TAB DESIGN */}
        <div className="hidden max_md:block mt-10">
          <div className="w-full grid grid-cols-3 gap-5 max_md:grid-cols-2 max max_600:grid-cols-1">
            {isLoading ? (
              <>
                {Array(3)
                  .fill(null)
                  .map((_, i) => (
                    <SwiperSlide key={i}>
                      <SkeletonCard />
                    </SwiperSlide>
                  ))}
              </>
            ) : (
              <>
                {allPlansData?.map((planInfo, index) => {
                  return (
                    <React.Fragment key={index}>
                      <PlanCard
                        planInfo={planInfo}
                        fullWidth
                        selected={selected}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans;
